import React, {useState} from 'react';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid2 from '@mui/material/Unstable_Grid2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import RuleIcon from '@mui/icons-material/Rule';

import {BrowserRouter, Route, Routes, useParams, useSearchParams} from "react-router-dom";
import {format, parse, parseISO} from 'date-fns';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import fr from "date-fns/locale/fr";
import {Helmet} from "react-helmet"
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import { v4 as uuidv4 } from 'uuid';

import './App.css';
import configData from './config.json';
import SportsConfig from './SportsConfig.json';
import {app_fetch, lowestAvailableNum, sortPlayers} from './utils'

import {Chrono} from "./components/Chrono";
import {EventsTable} from "./components/EventsTable";
import {InformationsTable} from "./components/InformationsTable";
import {HeaderBar} from "./components/HeaderBar";
import {LoadingButton} from "./components/LoadingButton";
import {OutlinedTitledBox} from "./components/OutlinedTitledBox";
import TeamSelector from "./components/TeamSelector";
import {Rules} from "./components/Rules";
import {TabsNavigationBar} from "./components/TabsNavigationBar";
import TeamSheet from "./components/TeamSheet";


const champsTheme = createTheme({
	palette: {
		primary: {
			main: "#64DCBE",
			contrastText: "#FFFFFF#",
		},
		secondary: {
			main: "#8CE6DE",
		},
		mode: "dark",
	},
	typography: {
		button: {
			fontWeight: "bold",
		},
	},
});


const getTimezoneOffset = value => value.getTimezoneOffset() * 60000;

const makeLocalAppearUTC = value => {
	const dateTime = new Date(value);
	const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
	return utcFromLocal;
};

const localToUTC = dateTime => {
	const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
	return utcFromLocal;
};


export default function App() {
	return (
		<ThemeProvider theme={champsTheme}>
			<Helmet htmlAttributes={{"lang": "fr"}} />
			<CssBaseline />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<LoginPage />} />
					<Route path="/:sport" element={<MatchPage />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

function LoginPage() {
	return (<>
		<HeaderBar disableHomeLogo={true} />
		<Login />
	</>);
}

function MatchPage() {
	const [searchParams] = useSearchParams();
	const codeClub = searchParams.get("code");;
	const codeRencontre = searchParams.get("rencontre");
	document.title = codeRencontre + " - Saisie des stats - Champ'S";

	const {sport} = useParams();
	const sportConfig = SportsConfig[sport];
	if (!sportConfig) {
		window.location.href = "/";
	}

	const [selectedTab, setSelectedTab] = useState("");
	const tabs =[
		(sportConfig.ruleset && {"icon": <RuleIcon/>, "id": "rules", "text": "Règles"}),
		{"icon": <PeopleOutlineIcon/>, "id": "teams", "text": "Équipes"},
		{"icon": <AddchartOutlinedIcon/>, "id": "stats", "text": "Statistiques"},
		{"icon": <InfoOutlinedIcon/>, "id": "infos", "text": "Informations"},
	].filter((value) => (value));

	return (
		<>
			<HeaderBar codeRencontre={codeRencontre} disableHomeLogo={false} sport={sport} />
			<TabsNavigationBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs}>
				<MatchTabs
					codeRencontre={codeRencontre}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
					sport={sport.replace(/\b\w/g, (str) => str.toUpperCase())}
					codeClub={codeClub}
					{...sportConfig}
				/>
			</TabsNavigationBar>
		</>
	);
}

class ConfirmationDialog extends React.Component {
	static propTypes = {
		"description": PropTypes.string.isRequired,
		"handleClose": PropTypes.func.isRequired,
		"open": PropTypes.bool.isRequired,
		"title": PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose(e) {
		this.props.handleClose(e.currentTarget.value);
	}

	render() {
		return <>
			<Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">
					{this.props.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{this.props.description}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button value="no" onClick={this.handleClose} autoFocus>Non</Button>
					<Button value="yes" onClick={this.handleClose}>Oui</Button>
				</DialogActions>
			</Dialog>
		</>;
	}
}

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.handleKeyEvent = this.handleKeyEvent.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			"codeRencontre": "",
			"codeClub": "",
		}

		this.buttonRef = React.createRef();
	}

	handleKeyEvent(event) {
		if (event.key === 'Enter') {
			this.buttonRef.current.onClick();
		}
	}

	handleSubmit(stopLoading) {
		if (!this.state.codeRencontre) {
			stopLoading("Veuillez saisir un code rencontre.");
			return;
		}
		let jsonData = JSON.stringify({
			"code_rencontre": this.state.codeRencontre,
			"stats_code": this.state.codeClub
		});
		const requestOptions = {
			"body": jsonData,
			"headers": { "Content-Type": "application/json" },
			"method": "POST",
			"mode": "cors"
		};
		app_fetch("/login", requestOptions,
			(data) => {
				if (data) {
					let url = new URL(window.location.href + data.sport.toLowerCase());
					url.searchParams.set("rencontre", data.code_rencontre);
					url.searchParams.set("code", data.stats_code);
					window.location.href = url;
				}
			},
			() => stopLoading("Les codes saisis ne sont pas valides.")
		)
	}

	render() {
		return <>
			<Grid2
				alignItems="center"
				container
				justifyContent="center"
				sx={{"bottom": 0, "position": "fixed", "top": 0}}
				width="100%"
			>
				<Grid2
					alignItems="center"
					container
					justifyContent="center"
					spacing={2}
					width="70%"
					xs={12}
				>
					<Grid2>
						<TextField
							label="Code rencontre"
							onChange={(event) => this.setState({"codeRencontre": event.target.value.trim()})}
							onKeyDown={this.handleKeyEvent}
							value={this.state.codeRencontre}
						/>
					</Grid2>
					<Grid2>
						<TextField
							label="Code club"
							onChange={(event) => this.setState({"codeClub": event.target.value.trim()})}
							onKeyDown={this.handleKeyEvent}
							value={this.state.codeClub}
						/>
					</Grid2>
					<Grid2 xs={12}>
						<Grid2 container justifyContent="center" margin={1}>
							<LoadingButton ref={this.buttonRef} onClickWithCallback={this.handleSubmit}>Valider</LoadingButton>
						</Grid2>
					</Grid2>
				</Grid2>
			</Grid2>
		</>;
	}
}

class MatchTabs extends React.Component {
	static propTypes = {
		"actions": PropTypes.arrayOf(PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object
		])).isRequired,
		"chronoConfig": PropTypes.object,
		"codeRencontre": PropTypes.string.isRequired,
		"hasComplexScore": PropTypes.bool,
		"nbCoaches": PropTypes.number.isRequired,
		"nbStartPlayers": PropTypes.number.isRequired,
		"periods": PropTypes.arrayOf(PropTypes.string).isRequired,
		"possession": PropTypes.bool,
		"ruleset": PropTypes.arrayOf(PropTypes.object),
		"selectedTab": PropTypes.string.isRequired,
		"setSelectedTab": PropTypes.func.isRequired,
		"sport": PropTypes.string.isRequired,
		"codeClub": PropTypes.string.isRequired,
		"streamUrl": PropTypes.string
	};

	constructor(props) {
		super(props);
		this.createMatchWithApi = this.createMatchWithApi.bind(this);
		this.createRulesWithApi = this.createRulesWithApi.bind(this);
		this.deleteEventFromApi = this.deleteEventFromApi.bind(this);
		this.findPlayerAWithNum = this.findPlayerAWithNum.bind(this);
		this.findPlayerBWithNum = this.findPlayerBWithNum.bind(this);
		this.getChronoFromApi = this.getChronoFromApi.bind(this);
		this.getComplexScoreFromApi = this.getComplexScoreFromApi.bind(this);
		this.getCurrentViewersFromApi = this.getCurrentViewersFromApi.bind(this);
		this.getEventsFromApi = this.getEventsFromApi.bind(this);
		this.getMatchFromApi = this.getMatchFromApi.bind(this);
		this.getPossessionFromApi = this.getPossessionFromApi.bind(this);
		this.getRulesFromApi = this.getRulesFromApi.bind(this);
		this.getScoreFromApi = this.getScoreFromApi.bind(this);
		this.handleAddNewPlayerA = this.handleAddNewPlayerA.bind(this);
		this.handleAddNewPlayerB = this.handleAddNewPlayerB.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleNextMatchTableTennis = this.handleNextMatchTableTennis.bind(this);
		this.handlePeriodChange = this.handlePeriodChange.bind(this);
		this.handlePlayerAChange = this.handlePlayerAChange.bind(this);
		this.handlePlayerBChange = this.handlePlayerBChange.bind(this);
		this.handleRuleChange = this.handleRuleChange.bind(this);
		this.handleSelectedActionChange = this.handleSelectedActionChange.bind(this);
		this.handleSelectedAPlayerChange = this.handleSelectedAPlayerChange.bind(this);
		this.handleSelectedBPlayerChange = this.handleSelectedBPlayerChange.bind(this);
		this.handleSelectedSubstAPlayerChange = this.handleSelectedSubstAPlayerChange.bind(this);
		this.handleSelectedSubstBPlayerChange = this.handleSelectedSubstBPlayerChange.bind(this);
		this.handleStartEndMatch = this.handleStartEndMatch.bind(this);
		this.handleValidateAction = this.handleValidateAction.bind(this);
		this.newPlayer = this.newPlayer.bind(this);
		this.sendChronoToApi = this.sendChronoToApi.bind(this);
		this.sendEventToApi = this.sendEventToApi.bind(this);
		this.sendPossessionToApi = this.sendPossessionToApi.bind(this);
		this.setMatchStarted = this.setMatchStarted.bind(this);
		this.updateNbPlayersFromRules = this.updateNbPlayersFromRules.bind(this);

		let selectedRules = {};
		if (this.props.ruleset != null) {
			for (let ruleGroup of this.props.ruleset) {
				selectedRules[ruleGroup.key] = ruleGroup.default ? ruleGroup.default : ruleGroup.rules[0].value;
			}
		}
		let now = Date.now();

		this.state = {
			"isLoading": true,
			"loadingError": false,

			// Action
			selectedAction: "",
			selectedAPlayer: 0,
			selectedBPlayer: 0,
			selectedSubstAPlayer: 0,
			selectedSubstBPlayer: 0,
			selectedTeam: "",

			// Chrono
			chrono: 0,
			chronoReverse: false,
			chronoStart: now,
			chronoStop: now,

			// Dialogs
			dialogOpen: false,

			// Events
			events: [],
			matchEnded: false,
			matchStarted: false,
			scoreIsNotNull: false,

			// Match
			championship: "",
			date: now,
			competition: {nom: this.props.sport},
			ivsData: {arn: "arn:aws:ivs:us-east-1:311122646616:channel/frNJVLwNaYh7", ingest_server: "rtmps://a03ddb580441.global-contribute.live-video.net:443/app/", stream_key: "sk_us-east-1_6IFbnudiTGTA_AjWyIHwylY4TvMqPuLJkdQzxRIU69U", stream_url: "https://a03ddb580441.us-east-1.playback.live-video.net/api/video/v1/us-east-1.311122646616.channel.frNJVLwNaYh7.m3u8"},
			organisator: "Champ'S",
			place: {id: "12", nom: "Champs", adresse: "Avenue Reille Paris 14"},

			// Periods
			allowPeriodChange: true,
			periods: this.props.periods,
			selectedPeriod: this.props.periods.length ? this.props.periods[0] : "",

			// Possession
			"possessionA": 0,
			"possessionB": 0,
			"possessionPause": 0,

			// Rules
			selectedRules: selectedRules,
			nbStartPlayers: this.props.nbStartPlayers,

			// Score
			complexScore: null,
			scoreA: "0",
			scoreB: "0",

			// Teams
			playersChanged: false,
			teamAid: "A",
			teamAName: "Receveur",
			teamAPlayers: [],
			teamASavedName: "Receveur",
			teamASavedPlayers: [],
			teamBid: "B",
			teamBName: "Visiteur",
			teamBPlayers: [],
			teamBSavedName: "Visiteur",
			teamBSavedPlayers: [],

			// Viewers
			viewers: 0,
		}
	}

	componentDidMount() {
		// Fetch information
		this.getMatchFromApi();
		this.getScoreFromApi();
		this.getPossessionFromApi();
		this.getEventsFromApi();
		this.getRulesFromApi();
		this.getCurrentViewersFromApi();
		this.getChronoFromApi();
	}

	componentDidUpdate(prevProps) {
		if (this.props.selectedTab !== prevProps.selectedTab) {
			window.scrollTo(0, 0);
		}
	}

	createMatchWithApi(stopLoading) {
		let sport = this.props.sport;
		let organisateur = this.state.organisator;
		let codeRencontre = this.props.codeRencontre;
		let date = format(makeLocalAppearUTC(this.state.date), "dd/MM/yyyy HH:mm");
		let lieu = this.state.place;
		let competition = this.state.competition;
		let championship = this.state.championship;
		let ivsData = this.state.ivsData;
		let equipeA = {public_name: this.state.teamAName, joueurs: this.state.teamAPlayers, id: this.state.teamAid}
		let equipeB = {public_name: this.state.teamBName, joueurs: this.state.teamBPlayers, id: this.state.teamBid}
		let jsonData = JSON.stringify({
			code_rencontre: codeRencontre,
			competition: competition,
			championnat: championship,
			channels: this.state.matchData.channels,
			date: date,
			equipe_a: equipeA,
			equipe_b: equipeB,
			is_test: this.state.matchData.is_test,
			ivsdata: ivsData,
			lieu: lieu,
			sport: sport,
			organisateur: organisateur,
		});
		let teamAPlayers = sortPlayers(this.state.teamAPlayers);
		let teamBPlayers = sortPlayers(this.state.teamBPlayers);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: jsonData,
			mode: "no-cors"
		};
		app_fetch(
			"/matches",
			requestOptions,
			undefined,
			undefined,
			() => this.setState(
				{
					"playersChanged": false,
					teamAPlayers,
					"teamASavedName": this.state.teamAName,
					"teamASavedPlayers": Array.from(teamAPlayers),
					teamBPlayers,
					"teamBSavedName": this.state.teamBName,
					"teamBSavedPlayers": Array.from(teamBPlayers),
				},
				stopLoading
			)
		);
	}

	createRulesWithApi(stopLoading) {
		this.updateNbPlayersFromRules()
		let jsonData = JSON.stringify(
			{
				code_rencontre: this.props.codeRencontre,
				sport: this.props.sport,
				timestamp: Date.now(),
				regles: this.state.selectedRules,
			}
		);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: jsonData,
			mode: "no-cors"
		};
		app_fetch("/rules", requestOptions, undefined, undefined, stopLoading);
	}

	deleteEventFromApi(id) {
		app_fetch("/events/"+this.props.codeRencontre+"/"+id, {"method": "DELETE"},
			undefined, undefined,
			()=> {
				this.getScoreFromApi(Date.now());
				this.getEventsFromApi();
			}
		);
	}

	findPlayerAWithNum(num) {
		return this.state.teamAPlayers.find((player) => (player.numero === num));
	}

	findPlayerBWithNum(num) {
		return this.state.teamBPlayers.find((player) => (player.numero === num));
	}

	getChronoFromApi() {
		app_fetch("/chrono/"+this.props.codeRencontre, {method : "GET", headers : {}},
			(data) => {
				if (data && data.start) {
					let period = this.state.selectedPeriod;
					if (data.period && data.period !== period) {
						period = data.period;
					}
					this.setState({
						selectedPeriod: period,
						chronoReverse: data.reverse,
						chronoStart: data.start,
						chronoStop: data.stop,
					});
				}
			}
		);
	}

	getComplexScoreFromApi(timestamp = null) {
		let apiPath = "/score/" + this.props.sport + "/" + this.props.codeRencontre;
		if (timestamp !== null) {
			apiPath = apiPath + "?ts=" + timestamp;
		}
		app_fetch(apiPath, {method : "GET", headers : {}},
			(data) => {
				if (data) {
					this.setState({"complexScore": data});
					if (data.manches) {
						this.setState({
							"allowPeriodChange": false,
							"periods": Object.keys(data.manches).sort((periodX, periodY) => {
								var matchX = periodX.split(" - ")[1];
								var matchY = periodY.split(" - ")[1];
								if (matchX !== matchY) {
									return (matchX > matchY) - (matchX < matchY);
								} else {
									return (periodX > periodY) - (periodX < periodY);
								}
							}),
						});
						if (data.en_cours) {
							this.handlePeriodChange(data.en_cours);
						} else {
							this.handlePeriodChange("undef");
						}
					}
				}
			}
		);
	}

	getCurrentViewersFromApi() {
		app_fetch(
			"/viewers/"+this.props.codeRencontre+"?current",
			{method : "GET", headers : {}},
			(data) => {
				if (data != null) {
					this.setState({viewers: data.viewers});
				}
			}
		);
	}

	getEventsFromApi() {
		app_fetch("/events/"+this.props.codeRencontre+"?with_id", {method : "GET", headers : {}},
			(data) => {
				let events = [];
				for (let ev of data) {
					if (ev.action.type === "match_start") {
						ev.action.description = "Début de la diffusion"
					} else if (ev.action.type === "match_end") {
						this.setState({matchEnded: true})
						ev.action.description = "Fin de la diffusion"
					} else if (!ev.action.description) {
						ev.action.description = ev.action.type.replace("_", " ");
						if (ev.action.player && ev.action.player.number) {
							ev.action.description += " du joueur N°" + ev.action.player.number;
							if (ev.action.player.name) {
								ev.action.description += " " + ev.action.player.name;
							}
						}
						if (ev.action.player && ev.action.player.team && ev.action.player.team === "A") {
							ev.action.description += " de l'équipe " + this.state.teamAName;
						}
						if (ev.action.player && ev.action.player.team && ev.action.player.team === "B") {
							ev.action.description += " de l'équipe " + this.state.teamBName;
						}
					} else if (ev.action.description.endsWith(" N°0 ")){
						ev.action.description = ev.action.description.replace(" JR N°0 ", " de l'équipe "+this.state.teamAName);
						ev.action.description = ev.action.description.replace(" JV N°0 ", " de l'équipe "+this.state.teamBName);
						ev.action.description = ev.action.description.replace(" N°0 ", "");
					} else {
						ev.action.description = ev.action.description.replace(/ JR (N°.*)/, " du joueur $1 de l'équipe "+this.state.teamAName);
						ev.action.description = ev.action.description.replace(/ JV (N°.*)/, " du joueur $1 de l'équipe "+this.state.teamBName);
					}
					events.push(ev);
				}
				if (events.length) {
					this.setState({matchStarted: true})
				}
				this.setState({events: events.sort((a, b) => (a.timestamp < b.timestamp))});
			}
		);
	}

	getMatchFromApi() {
		app_fetch("/matches/" + this.props.codeRencontre, {method : "GET", headers : {}},
			(data) => {
				const stringsDiffer = (strA, strB) => (strA.localeCompare(strB, undefined, {"sensitivity": "base"}) !== 0);
				if (
					stringsDiffer(data.code_rencontre, this.props.codeRencontre) ||
					stringsDiffer(data.sport_name, this.props.sport) /* ||
					(
						stringsDiffer(data.equipe_a.club_stats_code, this.props.codeClub) &&
						stringsDiffer(data.equipe_b.club_stats_code, this.props.codeClub)
					) */
				) {
					throw new Error("invalid match");
				}
				if (Object.keys(data).length === 0) {
					data = {
						"date": format(makeLocalAppearUTC(this.state.date), "dd/MM/yyyy HH:mm"),
						"equipe_a": {"joueurs": []},
						"equipe_b": {"joueurs": []},
						"ivsdata": {}
					};
				}
				let date = parse(data.date, "dd/MM/yyyy HH:mm", new Date());
				if (isNaN(date)) {
					date = parseISO(data.date);
				}
				if (!data.equipe_a.public_name) {
					data.equipe_a.public_name = this.state.teamAName;
				}
				if (!data.equipe_b.public_name) {
					data.equipe_b.public_name = this.state.teamBName;
				}
				if (this.state.teamAName !== data.equipe_a.public_name || this.state.teamBName !== data.equipe_b.public_name) {
					this.getEventsFromApi();
				}
				const fillTeamPlayersFromData = (dataPlayers) => {
					const teamPlayers = [];
					if (dataPlayers) {
						for (const player of dataPlayers) {
							if (!player.numero) {
								player.numero = lowestAvailableNum(dataPlayers);
							}
							if (!player.id) {
								player.id = uuidv4();
							}
							if (!player.roles) {
								player.roles = [];
							}
							teamPlayers.push(player);
						}
					}
					while (teamPlayers.length < this.state.nbStartPlayers) {
						teamPlayers.push(this.newPlayer(teamPlayers));
					}
					return sortPlayers(teamPlayers);
				};
				let teamAPlayers = fillTeamPlayersFromData(data.equipe_a.joueurs);
				let teamBPlayers = fillTeamPlayersFromData(data.equipe_b.joueurs);

				this.setState({
					"championship": data.championnat,
					"competition": data.competition,
					date,
					"isLoading": false,
					"ivsData": data.ivsdata,
					"matchData": data,
					"organisator": data.organisateur,
					"playersChanged": false,
					"teamAid": data.equipe_a.id ?? "A",
					"teamAName": data.equipe_a.public_name,
					"teamAPlayers": teamAPlayers,
					"teamASavedName": data.equipe_a.public_name,
					"teamASavedPlayers": Array.from(teamAPlayers),
					"teamBid": data.equipe_b.id ?? "B",
					"teamBName": data.equipe_b.public_name,
					"teamBPlayers": teamBPlayers,
					"teamBSavedName": data.equipe_b.public_name,
					"teamBSavedPlayers": Array.from(teamBPlayers),
				});
			},
			() => {
				this.setState(
					{"loadingError": true},
					() => setTimeout(() => (window.location.href = "/"), 2000)
				);
			}
		);
	}

	getPossessionFromApi() {
		if (!this.props.possession) {
			return;
		}
		app_fetch("/possession/"+this.props.codeRencontre, {method : "GET", headers : {}},
			(data) => {
				if (data && (data.secondsA || data.secondsB)) {
					this.setState({"possessionA": data.secondsA, "possessionB": data.secondsB});
				}
			}
		);
	}

	getRulesFromApi() {
		if (!this.props.ruleset) {
			return;
		}
		let apiPath = "/rules/" + this.props.sport + "/" + this.props.codeRencontre;
		app_fetch(apiPath, {method : "GET", headers : {}},
			(data) => {
				if (data && data.regles) {
					this.setState(
						{"selectedRules": data.regles},
						this.updateNbPlayersFromRules,
					);
				}
			}
		);
	}

	getScoreFromApi(timestamp = null) {
		let apiPath = "/stats/"+this.props.sport+"/"+this.props.codeRencontre;
		if (timestamp !== null) {
			apiPath = apiPath +"?ts="+timestamp;
		}
		app_fetch(apiPath, {method : "GET", headers : {}},
			(data) => {
				let scoreA = "0";
				let scoreB = "0";
				if (data.score) {
					scoreA = data.score.a;
					scoreB = data.score.b;
				}
				this.setState({scoreA, scoreB, "scoreIsNotNull": scoreA !== "0" || scoreB !== "0" || (data.score_table && data.score_table.a.length > 0)});
			}
		);
		if (this.props.hasComplexScore) {
			this.getComplexScoreFromApi(timestamp);
		}
	}

	handleAddNewPlayerA() {
		this.setState({"playersChanged": true, "teamAPlayers": [...this.state.teamAPlayers, this.newPlayer(this.state.teamAPlayers)]});
	}

	handleAddNewPlayerB() {
		this.setState({"playersChanged": true, "teamBPlayers": [...this.state.teamBPlayers, this.newPlayer(this.state.teamBPlayers)]});
	}

	handleDateChange(value) {
		this.setState({"date": value}, this.createMatchWithApi);
	}

	handleDialogClose(value) {
		if (value === "yes") {
			this.setState({"dialogOpen": false, "matchStarted": false, "matchEnded": true});
			// Send "match_end" event to API
			this.sendEventToApi({"type": "match_end"});
		}
		else {
			this.setState({"dialogOpen": false});
		}
	}

	handlePeriodChange(value) {
		this.setState(state => ({selectedPeriod: value}));
	}

	handlePlayerAChange(index, player, del=false) {
		const players = this.state.teamAPlayers;
		if (del) {
			if (player.id === players[index].id) {
				players.splice(index, 1);
			}
		} else {
			players[index] = player;
		}
		this.setState({"playersChanged": true, "teamAPlayers": players});
	}

	handlePlayerBChange(index, player, del=false) {
		const players = this.state.teamBPlayers;
		if (del) {
			if (player.id === players[index].id) {
				players.splice(index, 1);
			}
		} else {
			players[index] = player;
		}
		this.setState({"playersChanged": true, "teamBPlayers": players});
	}

	handleRuleChange(event) {
		let rules = this.state.selectedRules;
		rules[event.target.name] = event.target.value;
		this.setState(state => ({selectedRules: rules}));
	}

	handleSelectedActionChange(value) {
		if (this.state.selectedAction === value) {
			this.setState(state => ({selectedAction: ""}));
		}	else {
			this.setState(state => ({selectedAction: value}));
		}
	}

	handleSelectedAPlayerChange(value) {
		if (this.state.selectedAPlayer === value) {
			this.setState(state => ({selectedAPlayer: 0}));
		} else {
			this.setState(state => ({
				selectedAPlayer: value,
				selectedBPlayer: 0,
				selectedSubstBPlayer: 0,
				selectedTeam: "A",
			}));
		}
	}

	handleSelectedBPlayerChange(value) {
		if (this.state.selectedBPlayer === value) {
			this.setState(state => ({selectedBPlayer: 0}));
		} else {
			this.setState(state => ({
				selectedAPlayer: 0,
				selectedBPlayer: value,
				selectedSubstAPlayer: 0,
				selectedTeam: "B",
			}));
		}
	}

	handleSelectedSubstAPlayerChange(value) {
		if (this.state.selectedSubstAPlayer === value) {
			this.setState(state => ({selectedSubstAPlayer: 0}));
		} else {
			this.setState(state => ({
				selectedBPlayer: 0,
				selectedSubstAPlayer: value,
				selectedSubstBPlayer: 0,
				selectedTeam: "A",
			}));
		}
	}

	handleSelectedSubstBPlayerChange(value) {
			if (this.state.selectedSubstBPlayer === value) {
				this.setState(state => ({selectedSubstBPlayer: 0}));
			} else {
				this.setState(state => ({
					selectedAPlayer: 0,
					selectedSubstAPlayer: 0,
					selectedSubstBPlayer: value,
					selectedTeam: "B",
				}));
			}
	}

	handleStartEndMatch() {
		if (this.state.matchStarted) {
			this.setState({"dialogOpen": true});
		} else {
			this.setMatchStarted();
		}
	}


	handleValidateAction(stopLoading) {
		let action = {};
		let firstPlayer = null;
		let secondPlayer = null;

		if (this.state.selectedAction === "") {
			stopLoading("Veuillez sélectionner une action");
			return;
		}
		const specialActions = ["nouveau_match", "fin_de_mi-temps", "temps_mort"];
		if (this.state.selectedTeam === "" && !specialActions.includes(this.state.selectedAction)) {
			stopLoading("Veuillez sélectionner un joueur ou un équipe");
			return;
		}

		if (this.state.selectedAction === "temps_mort" && !this.state.chronoStop) {
			this.sendChronoToApi({start: this.state.chronoStart, stop: Date.now(), reverse: this.state.chronoReverse});
		}

		if (this.state.selectedTeam === "A") {
			firstPlayer = this.findPlayerAWithNum(this.state.selectedAPlayer);
			//secondPlayer = this.findPlayerBWithNum(this.state.selectedBPlayer);
		} else if (this.state.selectedTeam === "B") {
			firstPlayer = this.findPlayerBWithNum(this.state.selectedBPlayer);
			//secondPlayer = this.findPlayerAWithNum(this.state.selectedAPlayer);
		}
		// Specific case of substitution
		if (this.state.selectedAction === "remplacement") {
			if (this.state.selectedTeam === "A") {
				secondPlayer = this.findPlayerAWithNum(this.state.selectedSubstAPlayer);
			} else if (this.state.selectedTeam === "B") {
				secondPlayer = this.findPlayerBWithNum(this.state.selectedSubstBPlayer);
			}
			if (firstPlayer === null || secondPlayer === null) {
				stopLoading();
				return;
			}
			// Switch players locally // TODO
			if (this.state.selectedTeam === "A") {
				// use this.handlePlayerAChange(index, num, nom, type, isTemporary)
				// to update roles
				// firstPlayer role is now subst
				// secondPlayer role is now start
			}
			else if (this.state.selectedTeam === "B") {
				// use this.handlePlayerBChange(index, num, nom, type, isTemporary)
				// to update roles
				// firstPlayer role is now subst
				// secondPlayer role is now start
			}
		}

		action["type"] = this.state.selectedAction;
		if (this.state.selectedTeam !== "") {
			action["equipe"] = this.state.selectedTeam;
		}
		if (firstPlayer != null) {
			action.player = {
				"id": firstPlayer.id,
				"name": firstPlayer.nom,
				"number": firstPlayer.numero,
				"team": this.state.selectedTeam,
			}
			action["nom_joueur"] = firstPlayer.nom;
			action["numero_joueur"] = firstPlayer.numero;
		}
		if (secondPlayer != null) {
			action["nom_joueur_cible"] = secondPlayer.nom;
			action["numero_joueur_cible"] = secondPlayer.numero;
			if (this.state.selectedAction === "remplacement") {
				action["equipe_cible"] = action["equipe"];
			} else {
				action["equipe_cible"] = ["A","B"].filter((team) => {return team !== action["equipe"]})[0];
			}
		}

		// Send "match_start" event and automatically switch match as started
		this.setMatchStarted();

		if (this.props.sport === "Tennis De Table" && !this.state.scoreIsNotNull) {
			this.createMatchWithApi();
			this.createRulesWithApi();
		}

		// Send to API
		this.sendEventToApi(action, stopLoading);

		// Reset selection
		this.setState({"selectedAPlayer": 0, "selectedSubstAPlayer": 0, "selectedBPlayer": 0, "selectedSubstBPlayer": 0, "selectedAction": "", "selectedTeam": ""});
	}

	newPlayer(forTeam) {
		return {
			"id": uuidv4(),
			"is_temporary": true,
			"nom": "",
			"numero": lowestAvailableNum(forTeam),
			"roles": []
		};
	}

	sendChronoToApi(chronoState) {
		if (chronoState.stop) {
			this.setState({"possessionPause": Date.now()});
		}
		this.setMatchStarted();
		this.setState({
			"chronoReverse": chronoState.reverse,
			"chronoStart": chronoState.start,
			"chronoStop": chronoState.stop
		});
		const jsonData = JSON.stringify({
			"arn": this.state.ivsData.arn,
			"code_rencontre": this.props.codeRencontre,
			"pause_at_value": chronoState.periodStop,
			"period": this.state.selectedPeriod,
			"reverse": chronoState.reverse,
			"sport": this.props.sport,
			"start": chronoState.start,
			"stop": chronoState.stop,
			"timestamp": Date.now()
		});
		const requestOptions = {
			"body": jsonData,
			"headers": {"Content-Type": "application/json"},
			"method": "POST",
			"mode": "no-cors"
		};
		app_fetch("/chrono", requestOptions);
	}

	sendEventToApi(action, stopLoading=()=>{}) {
		let codeRencontre = this.props.codeRencontre;
		let codeClub = this.props.codeClub;
		let chrono = Math.floor(this.state.chrono);
		let timestamp = Date.now();
		let period = this.state.selectedPeriod;
		let sport = this.props.sport
		let arn = "arn:aws:ivs:us-east-1:311122646616:channel/wDPOf4v0KvE3"
		if (configData.ENVIRONMENT === "alpha") {
			arn = this.state.ivsData.arn;
		}
		let jsonData = JSON.stringify(
			{
				action: action,
				code_rencontre: codeRencontre,
				stats_code: codeClub,
				periode: period,
				chrono: chrono,
				timestamp: timestamp,
				arn: arn,
				sport: sport
			}
		);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: jsonData,
			mode: "no-cors"
		};
		app_fetch("/events", requestOptions,
			undefined, undefined,
			() => {
				this.getEventsFromApi();
				this.getScoreFromApi(timestamp);
				stopLoading();
			});
	}

	sendPossessionToApi(percentA, percentB, secondsA, secondsB) {
		if (this.state.chronoStop) {
			let pauseAtValue = 0;
			if (this.props.chronoConfig) {
				pauseAtValue = this.props.chronoConfig[this.state.selectedPeriod].stop;
			}
			this.sendChronoToApi({
				"pause_at_value": pauseAtValue,
				"reverse": this.state.chronoReverse,
				"start": this.state.chronoStart + (Date.now() - this.state.chronoStop),
				"stop": null
			});
		}
		const jsonData = JSON.stringify({
			"code_rencontre": this.props.codeRencontre,
			percentA,
			percentB,
			secondsA,
			secondsB,
			"sport": this.props.sport,
			"timestamp": Date.now()
		});
		const requestOptions = {
			"body": jsonData,
			"headers": {"Content-Type": "application/json"},
			"method": "POST",
			"mode": "no-cors"
		};
		app_fetch("/possession", requestOptions);
	}

	setMatchStarted() {
		if (!this.state.matchStarted && !this.matchEnded) {
			this.sendEventToApi({"type": "match_start"});
			this.createMatchWithApi();
			this.setState({"matchStarted": true});
		}
	}

	updateNbPlayersFromRules() {
		if (this.state.selectedRules["team"] === "simple") {
			this.setState(state => ({nbStartPlayers: 1}));
		}
		if (this.state.selectedRules["team"] === "double") {
			this.setState(state => ({nbStartPlayers: 2}));
		}
	}

	render() {
		const appAndroidUrl = "https://play.google.com/store/apps/details?id=com.champs";
		const appIOSUrl = "https://apps.apple.com/app/champs/id6444290143";
		const appAndroidQRCode = "https://apps.champs.fr/android_qr_code.png";
		const appIOSQRCode = "https://apps.champs.fr/ios_qr_code.png";
		const matchQRCode = process.env.REACT_APP_API_URL+"/alpha/qr/"+this.props.codeRencontre;
		const matchUrl = process.env.REACT_APP_API_URL+"/alpha/live/"+this.props.codeRencontre;

		const disableEdition = (this.props.sport !== "Tennis De Table" && this.state.matchStarted) || (this.props.sport === "Tennis De Table" && this.state.scoreIsNotNull)

		if (this.state.isLoading) {
			return (<>
				<Backdrop
					open={true}
					sx={{
						"backgroundColor": "black",
						"zIndex": (theme) => theme.zIndex.drawer
					}}
				>
					<Grid2
						container
						alignItems="center"
						justifyContent="center"
						style={{
							"left": "50%",
							"position": "absolute",
							"top": "50%",
							"transform": "translate(-50%, -50%)"
						}}
					>
						<CircularProgress disableShrink={true} size="100px" />
						<Grid2 xs={12} sx={{"padding": "20px", "textAlign": "center", "width": "100%"}}>
							Chargement des données du match.
						</Grid2>
					</Grid2>
					<Snackbar anchorOrigin={{"vertical": "bottom", "horizontal": "center"}} open={this.state.loadingError}>
						<Alert severity="error" variant="filled">
							Le chargement des données du match a échoué.
						</Alert>
					</Snackbar>
				</Backdrop>
			</>);
		}
		return (<>
			<Box display={this.props.selectedTab !== "infos" && "none"}>
				<InformationsTable
					informations={[
						{"description": "Télécharger l'application Android", "url": appAndroidUrl, "qrCodeUrl": appAndroidQRCode},
						{"description": "Télécharger l'application IOS", "url": appIOSUrl, "qrCodeUrl": appIOSQRCode},
						/*{"description": "Ouvrir le match dans l'application", "url": matchUrl, "qrCodeUrl": matchQRCode}*/
					]}
				/>
			</Box>
			<Box display={this.props.selectedTab !== "rules" && "none"}>
				{(this.props.ruleset) && (
					<Rules
						disabled={disableEdition}
						handleRuleChange={this.handleRuleChange}
						ruleset={this.props.ruleset}
						saveRules={this.createRulesWithApi}
						selectedRules={this.state.selectedRules}
					/>
				)}
			</Box>
			<Box display={this.props.selectedTab !== "teams" && "none"}>
				<Grid2 container spacing={2} alignItems="center" justifyContent="center">
					<Grid2 xs={12} justifyContent="center" display={this.state.championship ? "container" : "none"}>
						<OutlinedTitledBox title="Championnat">{this.state.championship}</OutlinedTitledBox>
					</Grid2>
					<Grid2 xs={12} justifyContent="center" container>
						<Grid2>
							<OutlinedTitledBox title="Date">
								<Grid2 container justifyContent="center">
									<Typography align="center">
										Match programmé pour le <b>
											{(new Date(this.state.date)).toLocaleDateString(fr, {"day": "numeric", "month": "long", "weekday": "long", "year": "numeric"})}
										</b> à
									</Typography>
									<Grid2 container justifyContent="center" xs={12}>
										<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
											<TimePicker
												value={this.state.date}
												onChange={this.handleDateChange}
												renderInput={(params) => <TextField {...params} />}
												InputProps={{"size": "small", "sx": {"input": {"textAlign": "center"}, "width": "150px"}}}
											/>
										</LocalizationProvider>
									</Grid2>
								</Grid2>
							</OutlinedTitledBox>
						</Grid2>
					</Grid2>
					<Grid2 xs={6} container alignItems="center" alignSelf="start" justifyContent="center">
						<TeamSheet
							disableEdition={disableEdition}
							disableNameEdition={this.state.teamAid !== "A"}
							disableNewPlayer={this.props.sport === "Tennis De Table" && this.state.teamAPlayers.length === this.state.nbStartPlayers}
							handleAddNewPlayer={this.handleAddNewPlayerA}
							handleNameChange={(event) => this.setState({teamAName: event.target.value, playersChanged: true})}
							handlePlayerChange={this.handlePlayerAChange}
							name={this.state.teamAName}
							players={this.state.teamAPlayers}
						/>
					</Grid2>
					<Grid2 xs={6} container alignItems="center" alignSelf="start" justifyContent="center">
						<TeamSheet
							disableEdition={disableEdition}
							disableNameEdition={this.state.teamBid !== "B"}
							disableNewPlayer={this.props.sport === "Tennis De Table" && this.state.teamBPlayers.length === this.state.nbStartPlayers}
							handleAddNewPlayer={this.handleAddNewPlayerB}
							handleNameChange={(event) => this.setState({teamBName: event.target.value, playersChanged: true})}
							handlePlayerChange={this.handlePlayerBChange}
							name={this.state.teamBName}
							players={this.state.teamBPlayers}
						/>
					</Grid2>
				</Grid2>
				<br />
				<Grid2 container justifyContent="center" margin={2} xs="12">
					<LoadingButton
						buttonProps={{
							"sx": {
								"margin": 1,
								"width": "150px"
							},
							"variant": "outlined",
						}}
						disabled={disableEdition || !this.state.playersChanged}
						onClickWithCallback={(stopLoading)=>{
							this.setState(
								{
									"teamAName": this.state.teamASavedName,
									"teamAPlayers": Array.from(this.state.teamASavedPlayers),
									"teamBName": this.state.teamBSavedName,
									"teamBPlayers": Array.from(this.state.teamBSavedPlayers),
								},
								()=>this.setState({"playersChanged": false}, stopLoading)
							)
						}}
					>
						Annuler
					</LoadingButton>
					<LoadingButton
						buttonProps={{"sx": {"margin": 1, "width": "150px"}}}
						disabled={disableEdition || !this.state.playersChanged}
						onClickWithCallback={this.createMatchWithApi}
					>
						Enregistrer
					</LoadingButton>
				</Grid2>
			</Box>
			<Box display={this.props.selectedTab !== "stats" && "none"}>
				<Grid2 container spacing={2} alignItems="center" justifyContent="center">
					<Grid2 xs={12} container justifyContent="center">
						<Grid2>{this.state.matchStarted ? "Terminer" : "Démarrer"} la diffusion</Grid2>
						<Switch disabled={this.state.matchEnded} checked={this.state.matchStarted} onChange={this.handleStartEndMatch}/>
						<ConfirmationDialog open={this.state.dialogOpen} handleClose={this.handleDialogClose} title="Terminer le match ?" description="Attention, une fois que le match est déclaré comme terminé, la reprise de la diffusion n'est plus possible. Confirmez-vous votre choix ?"/>
						{(!this.state.matchStarted && !this.state.matchEnded) && (
							<Grid2><Alert variant="filled" severity="warning">La diffusion n'est pas encore démarrée</Alert></Grid2>
						)}
						{(!this.state.matchStarted && this.state.matchEnded) && (
							<Grid2><Alert variant="filled" severity="info">La diffusion est terminée</Alert></Grid2>
						)}
					</Grid2>
					<Period
						selectedPeriod={this.state.selectedPeriod}
						periods={this.state.periods}
						handlePeriodChange={this.handlePeriodChange}
						allowManualChange={this.state.allowPeriodChange}
						complexScore={this.state.complexScore}
					/>
					&nbsp;
					<Grid2 xs={10} container justifyContent="center" spacing={2} rowSpacing={1} >
						<Viewers value={this.state.viewers} updateValue={this.getCurrentViewersFromApi}/>
						<Score scoreA={this.state.scoreA} scoreB={this.state.scoreB} />
						{(this.props.chronoConfig) && (
								<Chrono
									value={this.state.chrono}
									reverse={this.state.chronoReverse}
									start={this.state.chronoStart}
									stop={this.state.chronoStop}
									updateValue={(chrono) => this.setState({chrono})}
									sendChronoToApi={this.sendChronoToApi}
									config={this.props.chronoConfig}
									currentPeriod={this.state.selectedPeriod}
							/>
						)}
						<Grid2 xs={12}/>
						{(this.props.possession) && (
							<Possession sendToApi={this.sendPossessionToApi} secondsA={this.state.possessionA} secondsB={this.state.possessionB} pause={this.state.possessionPause}/>
						)}
					</Grid2>
					<Grid2 xs={4} container spacing={2} rowSpacing={1} alignItems="center" justifyContent="center">
						<TeamSelector
							handlePlayerSelect={this.handleSelectedAPlayerChange}
							name={this.state.teamAName}
							nbStartPlayers={this.state.nbStartPlayers}
							players={this.state.teamAPlayers}
							selectedPlayerNum={this.state.selectedAPlayer}
						/>
					</Grid2>
					<Grid2 xs={4}>
						<Grid2 container justifyContent="center" spacing={2}>
							{this.props.actions.map((action, index) => {
								switch (typeof action) {
								case "string":
									return <Grid2 key={index} xs="auto"><Action name={action} selected={this.state.selectedAction} onActionSelect={this.handleSelectedActionChange}/></Grid2>;
								case "object":
									return <Grid2 key={index} xs="auto"><Action name={action.name} selected={this.state.selectedAction} onActionSelect={this.handleSelectedActionChange} actionList={action.list}/></Grid2>;
								default:
									return "";
								}
							})}
							{(this.state.nbSubstPlayers > 0) && (
								<Grid2 xs="auto"><Action name="remplacement" selected={this.state.selectedAction === "remplacement"} onActionSelect={this.handleSelectedActionChange}/></Grid2>
							)}
							<Grid2 container justifyContent="center" xs={12} marginY={2}>
								<LoadingButton onClickWithCallback={this.handleValidateAction}>Valider</LoadingButton>
							</Grid2>
						</Grid2>
					</Grid2>
					<Grid2 xs={4} container spacing={2} rowSpacing={1} alignItems="center" justifyContent="center">
						<TeamSelector
							handlePlayerSelect={this.handleSelectedBPlayerChange}
							name={this.state.teamBName}
							nbStartPlayers={this.state.nbStartPlayers}
							players={this.state.teamBPlayers}
							selectedPlayerNum={this.state.selectedBPlayer}
						/>
					</Grid2>
					<Grid2
						sx={{
							"marginY": 1,
							"padding": 0,
							"textAlign": "center",
						}}
						xs={12}
					>
						{(this.state.selectedTeam === "A" && this.state.selectedAPlayer !== 0) ?
							(this.state.selectedAPlayer === -1) ?
								"Un joueur de l'équipe "+this.state.teamAName
							: (this.findPlayerAWithNum(this.state.selectedAPlayer).nom) ?
								this.findPlayerAWithNum(this.state.selectedAPlayer).nom+", n°"+this.state.selectedAPlayer+" de l'équipe "+this.state.teamAName
							:
								"Le joueur n°"+this.state.selectedAPlayer+" de l'équipe "+this.state.teamAName
						: (this.state.selectedTeam === "B" && this.state.selectedBPlayer !== 0) ?
							(this.state.selectedBPlayer === -1) ?
								"Un joueur de l'équipe "+this.state.teamBName
							: (this.findPlayerBWithNum(this.state.selectedBPlayer).nom) ?
								this.findPlayerBWithNum(this.state.selectedBPlayer).nom+", n°"+this.state.selectedBPlayer+" de l'équipe "+this.state.teamBName
							:
								"Le joueur n°"+this.state.selectedBPlayer+" de l'équipe "+this.state.teamBName
						:
							<Typography style={{"color": "grey"}}>Aucun joueur séléctionné</Typography>
						}
					</Grid2>
				</Grid2>
				<Grid2 sx={{"px": {"sm": "10%"}, "py": "20px"}}>
					<EventsTable
						deleteEventFunc={this.deleteEventFromApi}
						events={this.state.events}
						restrictDelete={this.props.hasComplexScore}
					/>
				</Grid2>
				{this.props.sport === "Tennis De Table" &&
					<Grid2 container justifyContent="center" marginY={2}>
						<LoadingButton onClickWithCallback={this.handleNextMatchTableTennis}>
							{"Passer au match suivant"}
						</LoadingButton>
					</Grid2>
				}
			</Box>
		</>);
	}

	handleNextMatchTableTennis(stopLoading = () => {}) {
		this.setState(
			{
				playersChanged: true,
				selectedAction: "nouveau_match",
				selectedTeam: "",
				teamAName: this.state.teamAid === "A" ? "Équipe A" : this.state.teamAName,
				teamAPlayers: [this.newPlayer([])],
				teamBName: this.state.teamBid === "B" ? "Équipe B" : this.state.teamBName,
				teamBPlayers: [this.newPlayer([])],
			},
			() => this.handleValidateAction(()=>{
				stopLoading();
				this.props.setSelectedTab("teams");
			}),
		);
	}
}

class Action extends React.Component {
	static propTypes = {
		"actionList": PropTypes.arrayOf(PropTypes.string),
		"name": PropTypes.string.isRequired,
		"onActionSelect": PropTypes.func.isRequired,
		"selected": PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.state = {"menuIsOpen": false};
		this.ref = React.createRef()
	}

	handleSelect(event) {
		this.props.onActionSelect(event.target.name);
		this.setState({"menuIsOpen": false});
	}

	render() {
		if (this.props.actionList != null) {
			return (<>
				<Menu
					anchorEl={this.ref.current}
					anchorOrigin={{"vertical": "bottom", "horizontal": "center"}}
					open={this.state.menuIsOpen}
					onClose={() => this.setState({"menuIsOpen": false})}
					PaperProps={{"sx": {"minWidth": "120px"}}}
					transformOrigin={{"vertical": "top", "horizontal": "center"}}
				>
					{this.props.actionList.map((action) => (
						<MenuItem key={action} sx={{"padding": "10px"}}>
							<Button
								fullWidth={true}
								name={action}
								onClick={this.handleSelect}
								size="small"
								sx={{ "fontSize": "10px" }}
								variant={this.props.selected === action ? "contained" : "outlined"}
							>{action.replaceAll('_', ' ')}</Button>
						</MenuItem>
					))}
				</Menu>
				<Button
					endIcon={<ExpandMoreIcon sx={{
						"margin": "3px",
						"marginRight": "5px",
						"position": "absolute",
						"right": 0,
						"top": 0
					}}/>}
					fullWidth={true}
					name={this.props.name}
					onClick={() => this.setState({"menuIsOpen": true})}
					ref={this.ref}
					size="small"
					sx={{"fontSize": "10px", "height": "100%", "width": "120px"}}
					variant={this.props.actionList.includes(this.props.selected) ? "contained" : "outlined"}
				>{this.props.name.replaceAll('_', ' ')}</Button>
			</>);
		} else {
			return (
				<Button
					name={this.props.name}
					onClick={this.handleSelect}
					size="small"
					sx={{"fontSize": "10px", "height": "100%", "width": "120px", "whiteSpace": "nowrap"}}
					variant={this.props.selected === this.props.name ? "contained" : "outlined"}
				>{this.props.name.replaceAll('_', ' ')}</Button>
			);
		}
	}
}

class Period extends React.Component {
	static propTypes = {
		"allowManualChange": PropTypes.bool.isRequired,
		"complexScore": PropTypes.object,
		"handlePeriodChange": PropTypes.func.isRequired,
		"periods": PropTypes.arrayOf(PropTypes.string).isRequired,
		"selectedPeriod": PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);
		this.getScoreForPeriod = this.getScoreForPeriod.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	getScoreForPeriod(period) {
		let score = "";
		if (this.props.complexScore != null && this.props.complexScore.manches != null) {
			let periodData = this.props.complexScore.manches[period];
			if (periodData != null) {
				if (periodData.points != null) {
					score = periodData.points.A + "-" + periodData.points.B;
				}
				if (periodData.jeux != null) {
					score = periodData.jeux.A + "-" + periodData.jeux.B;
					if (periodData["tie-break"] != null) {
						score += " (" + periodData["tie-break"]["A"] + "-" + periodData["tie-break"]["B"] + ")"
					}
				}
			}
		}
		return score;
	}

	handleClick(value) {
		if (this.props.allowManualChange) {
			this.props.handlePeriodChange(value);
		}
	}

	render() {
		return (
			<Grid2 xs={12} container justifyContent="center" spacing={1}>
				{this.props.periods.map((period, index) => {
					return (
						<Grid2 key={period} columns={1} container justifyContent="center">
							<Button xs={1} variant={this.props.selectedPeriod === period ? "contained" : "outlined"} size="small" onClick={() => this.handleClick(period)}>
								{period}
							</Button>
							<Grid2 xs={1} sx={{"display": "grid", "whiteSpace": "nowrap"}} justifyContent="center">
								{this.getScoreForPeriod(period)}
							</Grid2>
						</Grid2>
					);
				})}
			</Grid2>
		);
	}
}

class Possession extends React.Component {
	static propTypes = {
		"pause": PropTypes.number.isRequired,
		"secondsA": PropTypes.number.isRequired,
		"secondsB": PropTypes.number.isRequired,
		"sendToApi": PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.formatPercent = this.formatPercent.bind(this);
		this.handleStart = this.handleStart.bind(this);
		this.handleStop = this.handleStop.bind(this);
		this.updatePossession = this.updatePossession.bind(this);
		this.state = {
			"team": "",
			"secondsA": 0,
			"secondsB": 0,
			"percentA": 0.0,
			"percentB": 0.0,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.secondsA !== prevProps.secondsA || this.props.secondsB !== prevProps.secondsB) {
			this.updatePossession(this.props.secondsA, this.props.secondsB);
		}
		if (this.props.pause !== prevProps.pause) {
			this.handleStop();
		}
	}

	formatPercent(percent) {
		return percent.toLocaleString(fr, {style: "percent", minimumFractionDigits:0, roundingMode: "halfEven"});
	}

	handleStart(team) {
		this.setState({team});
		clearInterval(this.possessionIntervalId);
		this.possessionIntervalId = setInterval(() => {
			var secondsA = this.state.secondsA;
			var secondsB = this.state.secondsB;
			if (team === "A") {
				secondsA++;
			} else if (team === "B") {
				secondsB++;
			}
			this.updatePossession(secondsA, secondsB);
		}, 1000);
	}

	handleStop() {
		clearInterval(this.possessionIntervalId);
		this.setState({"team": ""});
	}

	updatePossession(secondsA, secondsB) {
		if (secondsA + secondsB === 0) {
			return;
		}
		const percentA = (Math.trunc(100 * secondsA / (secondsA + secondsB) - 50) + 50) / 100;
		const percentB = (Math.trunc(100 * secondsB / (secondsA + secondsB) - 50) + 50) / 100;
		if ((percentA !== this.state.percentA || percentB !== this.state.percentB) && (this.state.secondsA || this.state.secondsB)) {
			this.props.sendToApi(percentA, percentB, secondsA, secondsB);
		}
		this.setState({secondsA, secondsB, percentA, percentB})
	}

	render() {
		return (
			<OutlinedTitledBox title="Possession">
				<Grid2 xs={12} container alignItems="center" justifyContent="center">
					<Grid2 xs={4} container justifyContent="center">{this.formatPercent(this.state.percentA)}</Grid2>
					<Grid2 xs={4}/>
					<Grid2 xs={4} container justifyContent="center">{this.formatPercent(this.state.percentB)}</Grid2>
				</Grid2>
				<Grid2 xs={12} container alignItems="center" justifyContent="center">
					<Button xs={4} variant={this.state.team === "A" ? "contained" : "outlined"} onClick={() => this.handleStart("A")}>
						<ArrowLeftIcon fontSize="large"/>
					</Button>
					<Button xs={4} onClick={this.handleStop}>
						<ClearIcon sx={{"height": "35px"}}/>
					</Button>
					<Button xs={4} variant={this.state.team === "B" ? "contained" : "outlined"} onClick={() => this.handleStart("B")}>
						<ArrowRightIcon fontSize="large"/>
					</Button>
				</Grid2>
			</OutlinedTitledBox>
		);
	}
}

class Score extends React.Component {
	static propTypes = {
		"scoreA": PropTypes.string.isRequired,
		"scoreB": PropTypes.string.isRequired
	};

	render() {
		return (
			<OutlinedTitledBox title="Score">
				<Grid2 xs={12} container alignItems="center" justifyContent="center" height="100%">
					<Grid2><Avatar variant="square">{this.props.scoreA}</Avatar></Grid2>
					<Grid2 style={{fontWeight: "bold"}}>-</Grid2>
					<Grid2><Avatar variant="square">{this.props.scoreB}</Avatar></Grid2>
				</Grid2>
			</OutlinedTitledBox>
		);
	}
}

class Viewers extends React.Component {
	static propTypes = {
		"updateValue": PropTypes.func.isRequired,
		"value": PropTypes.number.isRequired
	};

	constructor(props) {
		super(props);

		this.refreshLoop = setInterval(() => {
			this.props.updateValue();
		}, 30000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshLoop);
	}

	render() {
		return (
			<OutlinedTitledBox title="Spectateurs">
				<Grid2 xs={12} container alignItems="center" justifyContent="center" style={{fontWeight: "bold", fontSize: "x-large", height: "100%"}}>
					{this.props.value}
				</Grid2>
			</OutlinedTitledBox>
		);
	}
}
